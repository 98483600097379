<template>
  <div ref="vCarousel" class="w-full h-full relative" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="w-full relative overflow-hidden">
      <div
        ref="container"
        class="scroll-container space-y-5"
        :style="{ height: `${containerHeight}px` }"
      >
        <div v-for="(item, index) in items" :key="index" class="w-full overflow-auto" :style="{ height: `${itemHeight}px` }">
          <slot :item="item" />
        </div>
      </div>
    </div>
    <div class="flex flex-cols w-9/12 mx-auto mb-10 mt-5 md:mt-2 sm:mt-0">
      <div class="w-full ml-3">
        <span class="text-white text-[22px] font-bold font-['Montserrat']">
          {{ currentIndex + 1 }} /
        </span>
        <span class="text-white text-[22px] font-medium font-['Montserrat']">
          {{ items.length }}
        </span>
      </div>
      <div class="w-full flex flex-cols justify-end space-x-2">
        <div
          class="w-10 h-10 origin-top-left rounded-full border-2 border-white flex items-center justify-center cursor-pointer"
          @click="debouncedPrevious"
        >
          <ArrowLeftIcon class="h-[20px] w-[20px] text-white" />
        </div>
        <div
          class="w-10 h-10 bg-orange-600 origin-top-left rounded-full border-2 border-white flex items-center justify-center cursor-pointer"
          @click="debouncedNext"
        >
          <ArrowRightIcon class="h-[20px] w-[20px] text-white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  itemsToShow: {
    type: Number,
    default: 1
  },
  itemHeight: {
    type: Number,
    default: 400
  },
  autoPlay: {
    type: Number,
    default: 0,
    validator: (value: number) => value === 0 || (value >= 1000 && value % 1000 === 0)
  }
})

const items = ref<Array<any>>([...props.items])
const currentIndex = ref<number>(0)
const componentSpace: number = 20
const container = ref<HTMLElement | null>(null)
const vCarousel = ref<HTMLElement | null>(null)
const totalItems = props.items.length
const movement: number = props.itemHeight + componentSpace
const isHovered = useElementHover(vCarousel)

const moveSlide = (direction: 'next' | 'prev') => {
  const containerRef = container.value
  if (!containerRef) { return }

  if (direction === 'next') {
    containerRef.style.transition = 'transform 1s'
    containerRef.style.transform = `translateY(-${movement}px)`
    currentIndex.value = (currentIndex.value + 1) % totalItems
    containerRef.ontransitionend = () => {
      containerRef.ontransitionend = null
      containerRef.style.transition = 'none'
      setTimeout(() => {
        containerRef.style.transform = 'translateY(0)'
        items.value.push(items.value.shift())
      }, 50)
    }
  } else if (direction === 'prev') {
    items.value.unshift(items.value.pop())
    containerRef.style.transition = '0s'
    containerRef.style.transform = `translateY(${-movement}px)`
    containerRef.offsetHeight // Trigger reflow
    containerRef.style.transition = '1s'
    containerRef.style.transform = 'translateY(0)'
    currentIndex.value = (currentIndex.value - 1 + totalItems) % totalItems
  }
}

const nextSlide = () => {
  moveSlide('next')
}

const prevSlide = () => {
  moveSlide('prev')
}

const debouncedNext = useDebounceFn(nextSlide, 500)
const debouncedPrevious = useDebounceFn(prevSlide, 500)

const { pause: stopAutoplay, resume: startAutoplay } = useIntervalFn(
  () => {
    if (!isHovered.value) {
      debouncedNext()
    }
  },
  props.autoPlay,
  { immediate: false }
)

const { stop: stopObserver } = useIntersectionObserver(
  vCarousel,
  ([{ isIntersecting }]) => {
    if(isIntersecting) {
      startAutoplay()
    }else {
      stopAutoplay()
    }
  }
)

onUnmounted(() => {
  stopObserver()
  stopAutoplay()
})

const handleTouchStart = () => {
  isHovered.value = true
}

const handleTouchEnd = () => {
  isHovered.value = false
}

const containerHeight = computed(() => {
  return (props.itemsToShow * props.itemHeight) + (props.itemsToShow * componentSpace)
})

</script>

<style scoped>
.scroll-container {
  transform: translateY(0);
}
</style>
